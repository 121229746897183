import React from "react";
import "../styles/HomeTwo.css";
import avatar from "../assets/avatar.png";

const title = "Guddi";

export default function HomeTwo() {
  return (
    <header>
      <div className="landing">
        <div className="landing-avatar">
          <img src={avatar} alt="avatar" width="256px" />
        </div>
        <div className="landing-title">
          <h1 className="landing-title-text">{title}</h1>
        </div>
        <div className="landing-reflection">
          <h1 className="landing-title-text">{title}</h1>
        </div>
      </div>
    </header>
  );
}
